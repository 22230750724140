// extracted by mini-css-extract-plugin
export var products = "style-mod--products--36yGw";
export var product = "style-mod--product--1XeO7";
export var productMainTitle = "style-mod--product-main-title--ze8tS";
export var productCard = "style-mod--product-card--1MXOw";
export var cardBody = "style-mod--card-body--FKrRc";
export var cardContent = "style-mod--card-content--zox3T";
export var mobPrice = "style-mod--mob-price--1ZGsf";
export var cardContentTitle = "style-mod--card-content-title--1My0c";
export var cardTitle = "style-mod--card-title--2Ce3n";
export var sub = "style-mod--sub--32BjH";
export var cardText = "style-mod--card-text--6oBP3";
export var cardButton = "style-mod--card-button--37psW";