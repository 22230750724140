import React, { useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import getImagePath from "helpers/get-image-path";
import Link from "components/link";
import { ser, initAnalytics } from "helpers/analytics";

import {
  product, productCard, cardBody, cardContent, products,
  cardTitle, sub, cardButton, mobPrice, cardContentTitle,
  productMainTitle
} from "./style.mod.scss";

const CardData = ({ data }) => {
  const { card, title } = data;
  var settings = {
    slidesPerView: 1.23,
    centeredSlides: true,
    spaceBetween: 20,
    pagination: { clickable: true },
    breakpoints: {
      567: {
        centeredSlides: false,
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        centeredSlides: false,
        pagination: false,
        spaceBetween: 65,
        slidesPerView: 3,
        noSwiping: true,
        noSwipingClass:"swiper-wrapper",
      }
    }
  };

  useEffect(() => {
    initAnalytics({ select: products });
  });

  return (
    <div className={products}>
      <Container>
        <h2 className={productMainTitle}>{title}</h2>
        <Swiper {...settings}>
          {
            card?.map((singleCard, index) => {
              const { image, productRef, subtitle, } = singleCard;
              return (
                <SwiperSlide key={index} className={product}>
                  <Card className={productCard} >
                    <Card.Img variant="top" srcSet={getImagePath(image, true)} alt={image?.alt} />
                    <Card.Body className={cardBody}>
                      <div className={cardContent}>
                        <div className={cardContentTitle}>
                          <Card.Title className={cardTitle}>{productRef.title}</Card.Title>
                          {productRef.minPrice &&
                            <p className={sub}>Ab {productRef.minPrice}&euro;</p>
                          }
                        </div>
                        <Card.Text>{subtitle}</Card.Text>
                        {productRef.minPrice &&
                          <span className={mobPrice}>Ab {productRef.minPrice}&euro;</span>
                        }
                      </div>
                      <Link
                        to={`/products/${productRef.handle}`}
                        className={cardButton}
                        data-track-event="productClick"
                        data-track-props={ser({
                          ecommerce: {
                            click: {
                              actionField: { list: "Product Page" },
                              products: [{
                                name: productRef.title,
                                id: productRef.handle,
                                price: productRef.minPrice,
                                brand: "MyOlav",
                                category: null,
                                variant: null,
                                position: index,
                              }]
                            }
                          },
                        })}
                      >
                        Produkt anzeigen
                      </Link>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
      </Container>
    </div>
  );
};

export default CardData;
